<template> 
    <Provider v-bind="provider_info"/>
</template>

<script>
import Provider from "@/components/Provider.vue";
export default {
    name: "Heather Pardun",
    components: {
        Provider
    },
    data() {
        return {
            provider_info: {
                name: "Heather Pardun",
                img_src: "photo-coming-soon.jpg",
                //undergrad: "Knox College",
                undergrad_year: 1996,
                professional_school: "University of Nebraska Medical Center",
                professional_school_year: 2010,
                biography: [ 
                    "Heather grew up in O'Neill, NE. She received her BSN in 1996 and MSN in 2010 from the University of Nebraska Medical Center. In January 2012 she was Board Certified by the American Nurses Credentialing Center.",
                    "Heather's special interests in medicine are in family practice and the emergency room. She enjoys watching area sports teams and activities.",
                ],
                certifications: [
                    "Advanced Cardiac Life Support (ACLS)",
                    "FMCSA Certified Medical Examiner (DOT exams)",
                    "Pediatric Advanced Life Support (PALS)"
                ],
                acceptsNewPatients: true,
                telemedicine: true
            }
        }
    }
}
</script>

<style scoped>

</style>